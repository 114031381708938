import React, { useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import AuthContext from '../contexts/Authentication';
import { validateRedirectPath } from '../utils/pathValidation';

const ProtectedRoute = () => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  if (!auth?.user) {
    // Validate and use state for redirect
    const validatedPath = validateRedirectPath(location.pathname);
    return <Navigate 
      to="/login" 
      replace 
      state={{ from: validatedPath }}
    />;
  }

  return <Outlet />;
};

export default ProtectedRoute; 