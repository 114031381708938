

import React, { useState } from "react";

import axios from '../axios';

import useAxiosPrivate from "../hooks/useAxiosPrivate.js";

import { Button, Dropdown, Menu, Input, InputNumber, Form, Checkbox, Select, List } from "antd";

import {DownOutlined} from '@ant-design/icons';

import {NFLteamMap, NFLteamItems, positionOptions, sportLeagueMap, timeframeStatusMap, timeframeTypeMap, positionItems, positionMap, TimeframeTypeCurrentTimeframe, currentNCAAMtimeframe} from '../CONSTANTS';

const { Option} = Select;

const  Admin = (props) => {


  const labelSorter = ((a,b)=>{
    if (a.label>b.label){
      return 1;
    }
    else {
      return -1;
    }
  })

  
  NFLteamItems.sort(labelSorter);

  const [view,setView] = useState("All");
  const [prevView,setPrevView] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const [league,setLeague] = useState("Select A League");
  const [team, setTeam] = useState(-1);
  const [newTeam,setNewTeam] = useState(-1);
  const [teamName, setTeamName] = useState("Select A Team");
  const [newTeamName,setNewTeamName] = useState("Select New Team");
  const [teamItems, setteamItems] = useState(NFLteamItems);
  const [teamMap, setteamMap] = useState(NFLteamMap);
  const [playerItemsAll, setPlayerItemsAll] = useState([]);
  const [playerItemsUse, setPlayerItemsUse] = useState([]);
  const [playerMap, setplayerMap] = useState(new Map());
  const [playerName,setPlayerName] = useState("Select A Player");
  const [player,setPlayer] = useState(-1);
  const [positionName,setPositionName] = useState("Select A Position");
  //const [position,setPosition] = useState(-1);
  const [isSuccess,setIsSuccess] = useState(false);
  const [displayData,setDisplayData] = useState(false);
  const [data,setData] = useState([]);


  const successfulUpdate = () => {
    setIsSuccess(true);
    setPrevView(view);
    setView("All");
  }

  const repeatAction = () => {
    setView(prevView);
    setIsSuccess(false);
  }


  const submitNewTeam = async (e) => {
    console.log("Adding Team to " + String(league));
    console.log(e);

    //to get team   -- Document.getelementbyid - so need to assign id to <Input> boxes
    //console.log(e.team);
    var sport_id;
    var league_id;
    var tf;
    if (league==='NFL'){
      //send NFL post to add team to db for nflteams
      sport_id = 1;
      league_id = 1;
      tf = TimeframeTypeCurrentTimeframe[league_id][2];
    }
    else if (league==='NCAAM'){
      sport_id = 2;
      league_id = 6;
      tf = currentNCAAMtimeframe;
    }
    else{
      return;
    }

    try{
      const data = {
        sport_id: sport_id,
        sport_league_id: league_id,
        position_id: 1,
        city: e.t_city,
        nickname: e.t_name,
        abbreviation: e.t_abbrev,
        conference: e.t_conf,
        division: e.t_div,
        createstock: true,
        timeframe: tf,
        ranking: e.t_rank,
        price: e.t_price,
        savehist: false

      }
      console.log("attempting to send data to server");
      console.log(data);
      const response =  await axiosPrivate.post('/team/add',data);
      console.log ("Response");
      console.log (response);
      successfulUpdate();
    }
    catch(err){
      console.log("error in add player: " + err);
    }

  }

  const submitNewPlayer = async (e) => {
    console.log ("Submitting new player");
    console.log("Adding Player to team id " + String(team) + ", the " + teamName);
    console.log(e);


    //to get team   -- Document.getelementbyid - so need to assign id to <Input> boxes
    //console.log(e.team);
    //console.log("ret " + ret);
    //console.log("Access");
    //console.log(accessToken);
    console.log(team);
    console.log(league);
    if (league==='NFL'){
      //send NFL post to add team to db for nflteams
      var data = {
        league: league,
        team: team,
        sport: 1,
        fname: e.p_fname,
        lname: e.p_lname,
        pos : e.p_pos,
        rookie: e.p_rookie,
        createstock: false,
        savehist: false

      }
      try{
        console.log("attempting to send data to server");
        console.log(data);
        const response =  await axiosPrivate.post('/player/add',data);
        console.log ("Response");
        console.log (response);
        successfulUpdate();
      }
      catch(err){
        console.log("error in add player: " + err);
      }
    }

  }


  const submitNewPlayerTeam = async (e) => {
    console.log("Updating team for Player " + playerName);
    console.log(e);

    const data = {
      team: newTeam,
      player: player,
      ent_id: player
    }
    console.log(data);
    const response = await axiosPrivate.post('/player/updatePlayerTeam',data);
    console.log ("response")
    console.log(response);
    successfulUpdate();

  }

  const activatePlayer = async (e,activation) => {
    console.log("Activation or In-activation for " + playerName);
    console.log(e);

      const data = {
        player: player,
        ent_id: player,
        activate: activation
      }
      console.log(data);
      const response = await axiosPrivate.put('/player/activate',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();

  }

  const haltPlayer = async (e,halted) => {
    console.log("Halt or resume for " + playerName);
    console.log(e);

      const data = {
        player: player,
        ent_id: player,
        halt: halted
      }
      console.log(data);
      const response = await axiosPrivate.put('/player/halt',data);
      console.log ("response")
      console.log(response);
      successfulUpdate();

  }

  const exportStockToTimeframe = async (e) => {
    console.log(e);
    const data = {
      league: 1,
      ent_id: player,
      team_id: team,
      year: e.year,
      type: e.timeframe,
      savehist: e.p_savehist,
    }
    if (e?.p_price){
      data.price = e.p_price
    }
    await axiosPrivate.put('/market/createStockInTimeframe',data);
    successfulUpdate();
  }

  const exportTeamStockToTimeframe = async (e) => {
    console.log(e);
    const data = {
      league: 1,
      ent_id: team,
      team_id: team,
      year: e.year,
      type: e.timeframe,
      savehist: e.p_savehist,
    }
    if (e?.p_price){
      data.price = e.p_price
    }
    await axiosPrivate.put('/market/createStockInTimeframe',data);
    successfulUpdate();
  }

  const exportTeamToTimeframe = async (e) => {
    console.log(e);
    const data = {
      league: 1,
      team: team,
      year: e.year,
      type: e.timeframe
    }
    await axiosPrivate.put('/market/createStocksForTeamInTimeframe',data);
    successfulUpdate();
  }

  const updateUserPass = async (e) => {
     console.log(e);

     /*const data = {
      username: player,
      tmppass: player
    } */
    //console.log(data);
    const response = await axiosPrivate.put('/auth/adminUpdatePassword',e);
    console.log ("response")
    console.log(response);
    successfulUpdate();
  }




  const handleLeagueMenuClick = (e) => {
    //console.log("click ", e);
    console.log("handleleaguemenuclick");
    console.log("Choose League");
    console.log(e);
    //console.log(e.key);
    //console.log("Choosing league: " + e.key);
    //setLeague(e.key);
  };


  const handleLeagueClick = (e) => {
    //console.log("click ", e);
    console.log("Handle League Click");
    console.log(e);
    console.log(e.key);
    setLeague(e.key);
    setTeam(-1);
    setTeamName("Select A Team");
    setPlayerName("Select A Player");
    fillTeamMenu(e.key);
  };

  const handleTeamMenuClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Team Menu Click");
    console.log("Choose Team");
    console.log(e);

  };

  const handlePositionMenuClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Position Menu Click");
    console.log("Choose Position");
    console.log(e);

  };


  const fillTeamMenu = (league) => {
    console.log("Filling the teams");
   /* var myMap = new Map();
    axios.get('/getmarket/NFLteams')
    .then((res) => {
      console.log("res is " + res);
    console.log("res.data is " + res.data); //debugger
    console.log(res.data);
    var teamItemsloc = [];
    for (let i=0; i<res.data.length; ++i){
      var obj;
      var record = res.data[i];
      obj = {
        label: record.city + " " + record.nickname,
        key: record.team_id

      }
      //console.log("Obj " + i)
      //console.log(obj)
      myMap.set(record.team_id,obj.label)
      teamItemsloc.push(obj)
    }
    */
  console.log("teamItems old")
  console.log(teamItems)
  console.log("teamItems new")
  console.log(NFLteamItems)

  console.log("local team Map")

  console.log(NFLteamMap);
  NFLteamItems.sort(labelSorter);
  setteamItems(NFLteamItems);
  setteamMap(NFLteamMap);

  };

  const handleTeamClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Team Click");
    console.log(e);
    console.log("team key: " + e.key);
    console.log(e.key);
    console.log(teamMap)
    console.log("team corresponding to key: " + teamMap.get(parseInt(e.key)))
    setTeam(parseInt(e.key));
    setTeamName(teamMap.get(parseInt(e.key)))
    setPlayerName("Select A Player");
    if (view==='Price' || view==="PlayerTrade" || view==="PlayerDiv" || view==="NFLinactive" || view==="NFLunhalt" || view==="NFLfullhalt" || view==="NFLbuyhalt"|| view==="NFLlonghalt"|| view==="NFLshorthalt"|| view==="NFLsellhalt"|| view==="playerhistoryupdate" || view==="timeframecreate") {
      fillPlayerMenu(e.key);
    }
  };


  const handlePositionClick = (e) => {
    //console.log("click ", e);
    console.log("Handle Position Click");
    console.log(e);
    console.log("pos key: " + e.key);
    console.log(e.key);

    const pos_id = parseInt(e.key)
    //console.log(positionMap)
    //console.log("position corresponding to key: " + positionMap.get(pos_id))
    //setPosition(parseInt(e.key));
    setPositionName(positionMap[pos_id])

    //console.log(playerItemsAll.filter((player) => player.pos_id===pos_id))

    const filteredPlayers = playerItemsAll.filter((player) => player.pos_id===pos_id);

    var stillGood = false;
    for (let i=0; i<filteredPlayers.length; i++){
      if (filteredPlayers[i].key===player){
        //console.log("Still Good")
        stillGood = true;
        break;
      }
    }

    setPlayerItemsUse(filteredPlayers);
    if (!stillGood) {
      setPlayerName("Select A Player");
    }



  };


  const handleNewTeamClick = (e) => {
    //console.log("click ", e);
    console.log("Handle New Team Click");
    console.log(e);
    console.log("team key: " + e.key);
    console.log(e.key);
    console.log(teamMap)
    console.log("team corresponding to key: " + teamMap.get(parseInt(e.key)))
    setNewTeam(e.key);
    setNewTeamName(teamMap.get(parseInt(e.key)))
  };

  const getInactivePlayers = async() =>{
    setPlayer(-1);
    setPlayerName("Select A Player");
    try {
      const response = await axiosPrivate.get('/player/inactive');
      console.log(response.data);
      var playerItemsLoc = [];
      var myMap = new Map();
      for (let i=0; i<response.data.length; ++i){
        const record = response.data[i];
        const playerName = record.fname + " " + record.lname;
        const pid = record.player_id;
        const pos = record.position_id;
        playerItemsLoc.push({
            label: playerName,
            key: pid,
            pos_id: pos
        })
        myMap.set(pid,playerName)
      }
      console.log("playerItems")
      console.log(playerItemsLoc)
      playerItemsLoc.sort(labelSorter);
      setPlayerItemsAll(playerItemsLoc)
      setPlayerItemsUse(playerItemsLoc)
      setplayerMap(myMap);
    }
    catch (err) {

    }
  }

  const fillPlayerMenu = (team) => {
    console.log("Filling the players");
    console.log(team)
    const data = {
      team: team
    }
    var myMap = new Map();
    axios.post('/getmarket/players',data)
    .then((res) => {
      console.log("res is " + res);
      console.log("res.data is " + res.data); //debugger
      console.log(res.data);
      var playerItemsLoc = [];

      for (let i=0; i<res.data.length; ++i){
        const record = res.data[i];
        const playerName = record.fname + " " + record.lname;
        const pid = record.player_id;
        const pos = record.position_id;
        playerItemsLoc.push({
            label: playerName,
            key: pid,
            pos_id: pos
        })
        myMap.set(pid,playerName)
      }
      console.log("playerItems")
      console.log(playerItemsLoc)
      playerItemsLoc.sort(labelSorter);
      setPlayerItemsAll(playerItemsLoc)
      setPlayerItemsUse(playerItemsLoc)
      setplayerMap(myMap);

      console.log(playerItemsUse);
    })
  };

  const printTimeframeInfo = async(e) => {
    try {
      const response = await axiosPrivate.get('/getmarket/allTimeframes');
      console.log(response.data);
      var timeframes = [];
      for (let i=0; i<response.data.length; i++){
        const tf = response.data[i];
        var timeframe = tf.timeframe_name;
        timeframe = timeframe + ": " + sportLeagueMap[tf.sport_league_id] + " " + timeframeStatusMap[tf.tf_status]
                    + " " + tf.time_year + " Week-" + tf.subtimeframe + " Type-" + timeframeTypeMap[tf.time_type] + " ID: " + tf.timeframe_id;
        timeframes.push(timeframe);
      }

      setData(timeframes);
      setDisplayData(true);

    }
    catch (err) {

    }
  }

  const setTimeframeStatus = async (e,activation) => {
    console.log("Activation or In-activation for " + e.timeframe);
    console.log(e);

      const data = {
        timeframe: e.timeframe
      }
      console.log(data);

      if (activation===1){
        const response = await axiosPrivate.put('/timeframe/start',data);
        console.log ("response")
        console.log(response);
      }
      else { //activation===2
        const response = await axiosPrivate.put('/timeframe/end',data);
        console.log ("response")
        console.log(response);
      }

      successfulUpdate();

  }

  const handlePlayerClick = (e) => {
    //console.log("click ", e);
    console.log("Chose Player");
    console.log(e);
    console.log(e.key);
    setPlayer(parseInt(e.key));
    setPlayerName(playerMap.get(parseInt(e.key)))
  };


  const handlePlayerMenuClick = (e) => {
    //console.log("click ", e);

    console.log("Handle Player Menu Click");
    console.log("Choose a Player");
    console.log(e);

  };


  const LeagueMenu = () => (
    <Menu onClick={handleLeagueClick}>
      <Menu.Item key="NFL">NFL</Menu.Item>
      <Menu.Item key="NCAAM">NCAAM</Menu.Item>
    </Menu>
  );


  const TeamMenu = () => (
    <Menu onClick={handleTeamClick} items={teamItems}>
    </Menu>
  );

  const NewTeamMenu = () => (
    <Menu onClick={handleNewTeamClick} items={teamItems}>
    </Menu>
  );


  const PlayerMenu = () => (
    <Menu onClick={handlePlayerClick} items = {playerItemsUse}>
    </Menu>
  );

  const PositionMenu = () => (
    <Menu onClick={handlePositionClick} items={positionItems}>
    </Menu>
  );

  const ChooseLeague = () => (
    <Dropdown trigger={['click']} overlay={LeagueMenu}>
      <Button onClick={handleLeagueMenuClick}>{league}<DownOutlined /></Button>

    </Dropdown>
  );

  const ChooseTeam = () => (
    <Dropdown trigger={['click']} overlay={TeamMenu}>
      <Button onClick={handleTeamMenuClick}>{teamName}<DownOutlined /></Button>

    </Dropdown>
  );

  const ChooseTeamNew = () => (
    <Dropdown trigger={['click']} overlay={NewTeamMenu}>
      <Button onClick={handleTeamMenuClick}>{newTeamName}<DownOutlined /></Button>

    </Dropdown>
  );


  const ChoosePlayer = () => (
    <Dropdown trigger={['click']} overlay={PlayerMenu}>
    <Button onClick={handlePlayerMenuClick}>{playerName}<DownOutlined /></Button>

  </Dropdown>
  );


  const ChooseInactivePlayer = () => (
    <Dropdown trigger={['click']} overlay={PlayerMenu}>
    <Button onClick={handlePlayerMenuClick}>{playerName}<DownOutlined /></Button>

  </Dropdown>
  );

  const ChoosePosition = () => (
    <Dropdown trigger={['click']} overlay={PositionMenu}>
      <Button onClick={handlePositionMenuClick}>{positionName}<DownOutlined /></Button>

    </Dropdown>
  );



  const handleClickAdmin = (e) => {
    //console.log("click ", e);
    console.log("handle click Admin");
    console.log(e);
    console.log(e.key);
    setDisplayData(false);
    setIsSuccess(false);
    setView(e.key);

  };

  /*const seasonTypeItems = [
    {
      label: "Calendar Year",
      key: 1
    },
    {
      label: "Regular Season",
      key: 2
    },
    {
      label: "Playoffs",
      key: 3
    }
    {
      label: "All-Star Game",
      key: 4
    },
    {
      label: " In-Season Tournament",
      key: 5
    } 

  ]
*/

  const componentMap = {
    "": null,
    Team: (
      <div>
        <ChooseLeague></ChooseLeague>
        {league!=='Select A League' && <div>
          <Form onFinish={submitNewTeam}>
            <Form.Item label="Team City" name="t_city" rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Team Nickname" name="t_name" rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Team Abbreviation" name="t_abbrev" rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Team Conference" name="t_conf" rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label="Team Division" name="t_div" >
              <Input />
            </Form.Item>
            <Form.Item label="Rank" name="t_rank" >
              <InputNumber min={0} precision={0}/>
            </Form.Item>
            <Form.Item label='Price' name='t_price'>
              <InputNumber />
           </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType="submit">Submit</Button>
            </Form.Item> 
          </Form>
          </div>}

      </div> 
    ),
    Player: (
      <div>
        {<ChooseLeague></ChooseLeague>}
        {league!=='Select A League' && <ChooseTeam></ChooseTeam>} 
        {teamName!=='Select A Team' && <div>
          <Form onFinish={submitNewPlayer}> 
          {/* <Form onFinish = {(e) => submitNewPlayer(e,usr)}> */}
            <Form.Item label='Player First Name' name='p_fname' rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player Last Name' name='p_lname' rules={[{required:true}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player Position' name='p_pos' rules={[{required:true}]}>
              <Select placeholder="Select a Position" style={{ width: 200 }}  options={positionOptions}>
              </Select>
            </Form.Item>
            <Form.Item label='Rookie'  name='p_rookie' valuePropName="checked" initialValue={false}>
              <Checkbox >Rookie</Checkbox>
            </Form.Item>
            {/*<Form.Item label='Player Number' name='p_num' rules={[{required:false}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player College' name='p_college' rules={[{required:false}]}>
              <Input />
            </Form.Item>
            <Form.Item label='Player Age' name='p_age' rules={[{required:false}]}>
              <Input />
            </Form.Item>*/}
            <Form.Item>
              <Button type='primary' htmlType="submit">Submit</Button>
            </Form.Item> 
          </Form>
          </div>}
      </div>
    ),
    PlayerTrade: (
      <div>
        {<ChooseLeague></ChooseLeague>}
       {league!=='Select A League' && <ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <ChooseTeamNew></ChooseTeamNew>}
       {newTeamName!=="Select A Player" && <div>

          <Button type='primary' htmlType="submit" onClick={submitNewPlayerTeam}>Submit</Button>
      </div>}
      </div>
    ),
    NFLactive: (
      <div>
        {<ChoosePosition></ChoosePosition>}
       {<ChooseInactivePlayer></ChooseInactivePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>activatePlayer(e,1)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Activate Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLinactive: (
      <div>
        {<ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>activatePlayer(e,0)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Un-Activate Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLfullhalt: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>haltPlayer(e,1)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Halt Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLbuyhalt: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>haltPlayer(e,2)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Halt Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLlonghalt: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>haltPlayer(e,3)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Halt Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLsellhalt: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>haltPlayer(e,4)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Halt Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLshorthalt: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>haltPlayer(e,5)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Halt Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    NFLunhalt: (
      <div>
        {<ChooseTeam></ChooseTeam>}
       {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
       {playerName!=="Select A Player" && <div>
       <Form onFinish={e=>haltPlayer(e,0)}>
           <Form.Item>
             <Button type='primary' htmlType="submit">Resume Player</Button>
           </Form.Item>
         </Form>
      </div>}
      </div>
    ),
    timeframecreate: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=='Select A Team' && <ChoosePlayer></ChoosePlayer>}
        {playerName!=="Select A Team" && <div>
      <Form onFinish={e=>exportStockToTimeframe(e)}>
        <Form.Item label="Year" name="year" rules={[{ required: true, message: "Provide a Year." }]}>
          <InputNumber min={2025} precision={0}/>
        </Form.Item>
        <Form.Item label="Timeframe" name="timeframe">
          <Select placeholder="Select a Timeframe" style={{ width: 200 }}>
            <Option value={1}>Calendar Year</Option>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        </Form.Item>
        <Form.Item label='Add to Equity history week 0'  name='p_savehist' valuePropName="checked" initialValue={false}>
          <Checkbox >Yes</Checkbox>
        </Form.Item>
        <Form.Item label='Player Price' name='p_price' rules={[{required:false}]}>
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Export to Timeframe</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    timeframecreateteamstock: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=="Select A Team" && <div>
      <Form onFinish={e=>exportTeamStockToTimeframe(e)}>
        <Form.Item label="Year" name="year" rules={[{ required: true, message: "Provide a Year." }]}>
          <InputNumber min={2025} precision={0}/>
        </Form.Item>
        <Form.Item label="Timeframe" name="timeframe">
          <Select placeholder="Select a Timeframe" style={{ width: 200 }}>
            <Option value={1}>Calendar Year</Option>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        </Form.Item>
        <Form.Item label='Add to Equity history week 0'  name='p_savehist' valuePropName="checked" initialValue={false}>
          <Checkbox >Yes</Checkbox>
        </Form.Item>
        <Form.Item label='Team Price' name='p_price' rules={[{required:false}]}>
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Export to Timeframe</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    timeframecreateteam: (
      <div>
        {<ChooseTeam></ChooseTeam>}
        {teamName!=="Select A Team" && <div>
      <Form onFinish={e=>exportTeamToTimeframe(e)}>
        <Form.Item label="Year" name="year" rules={[{ required: true, message: "Provide a Year." }]}>
          <InputNumber min={2025} precision={0}/>
        </Form.Item>
        <Form.Item label="Timeframe" name="timeframe">
          <Select placeholder="Select a Timeframe" style={{ width: 200 }}>
            <Option value={1}>Calendar Year</Option>
            <Option value={2}>Regular Season</Option>
            <Option value={3}>Playoffs</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType="submit">Export to Timeframe</Button>
        </Form.Item>
     </Form>
     </div>}
    </div>
    ),
    userpassupd: (
      <div>
        <Form onFinish={e=>updateUserPass(e)}>
          <Form.Item label="Username" name="username" rules={[{ required: true, message: "Provide a username." }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="tmppass" rules={[{ required: true, message: "Provide a password." }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">Update User Password</Button>
          </Form.Item>
       </Form>
      </div>
    ),
    prnttmefrmeinfo: (
      <div>
        <Form onFinish={e=>printTimeframeInfo(e)}>
          <Form.Item>
            <Button type='primary' htmlType="submit">Print Timeframe Info</Button>
          </Form.Item>
       </Form>
      </div>
    ),
    starttimeframe: (
      <div>


        <Form onFinish={e=>setTimeframeStatus(e,1)}>
          <Form.Item label="Timeframe" name="timeframe" rules={[{ required: true, message: "Provide a Timeframe" }]}>
            <InputNumber min={5} precision={0}/>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">Start Timeframe</Button>
          </Form.Item>
        </Form>

      </div>
    ),
    endtimeframe: (
      <div>


        <Form onFinish={e=>setTimeframeStatus(e,2)}>
          <Form.Item label="Timeframe" name="timeframe" rules={[{ required: true, message: "Provide a Timeframe" }]}>
            <InputNumber min={5} precision={0}/>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType="submit">End Timeframe</Button>
          </Form.Item>
        </Form>

      </div>
    )
  };

  return (
      <div>
        {isSuccess && <div>{prevView} Update Successful</div>}
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="Team"><Button>Add a Team</Button></Menu.Item>
            <Menu.Item key="Player"><Button>Add a Player</Button></Menu.Item>
            <Menu.Item key="PlayerTrade"><Button>Update Player Team</Button></Menu.Item>
            {/*<Menu.Item key="TeamUpdate"><Button>Update Team Name</Button></Menu.Item>*/}
            {/*<Menu.Item key="PlayerUpdate"><Button>Update Player Name</Button></Menu.Item>*/}
        </Menu>
        {<Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="NFLactive" onClick={getInactivePlayers}><Button>Activate NFL Player</Button></Menu.Item>
            <Menu.Item key="NFLinactive"><Button>Make NFL Player Inactive</Button></Menu.Item>
          </Menu>}
          <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="NFLfullhalt"><Button>Halt NFL Player</Button></Menu.Item>
            <Menu.Item key="NFLbuyhalt"><Button>Halt Buying Player</Button></Menu.Item>
            <Menu.Item key="NFLlonghalt"><Button>Halt New Long Player</Button></Menu.Item>
            <Menu.Item key="NFLsellhalt"><Button>Halt Selling Player</Button></Menu.Item>
            <Menu.Item key="NFLshorthalt"><Button>Halt New Shorting Player</Button></Menu.Item>
          </Menu>
          <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="NFLunhalt"><Button>Un-Halt (Resume) NFL Player</Button></Menu.Item>
          </Menu>
          <Menu onClick={handleClickAdmin} mode='horizontal'>
          <Menu.Item key="timeframecreate"><Button>Create Stock for New Timeframe</Button></Menu.Item>
          <Menu.Item key="timeframecreateteamstock"><Button>Create Team Stock for New Timeframe</Button></Menu.Item>
          <Menu.Item key="timeframecreateteam"><Button>Create Stocks from Team for New Timeframe</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="userpassupd"><Button>Update User Password</Button></Menu.Item>
        </Menu>
        <Menu onClick={handleClickAdmin} mode='horizontal'>
            <Menu.Item key="prnttmefrmeinfo"><Button>Print Timeframe Info</Button></Menu.Item>
            <Menu.Item key="starttimeframe"><Button>Start Timeframe</Button></Menu.Item>
            <Menu.Item key="endtimeframe"><Button>End Timeframe</Button></Menu.Item>
        </Menu>
        {isSuccess && <Button onClick={repeatAction}>Repeat Action</Button>}
        {view!=="All" && <div>{view}</div>}
        {componentMap[view]}
        {displayData && 
          <div>
            <List size="small" header= {<div>Results</div>} bordered dataSource={data} renderItem={(item) => <List.Item>{item}</List.Item>}>
            </List>
          </div>}
      </div>
  );
  
}

export default Admin;
