import React from "react";
import Admin from "./Admin/Admin.js";
import League from "./League/League.js";
//import RestrictedNFLTable from "./RestrictedNFLTable.js";
import Market from "./Market.js";
import AdminStock from "./Admin/AdminStock.js";
import AdminGame from "./Admin/AdminGame.js";
//import RestrictedTable from "./RestrictedTable.js";
import About from "./About.js";
import SiteNews from './SiteNews.js';
import CreatePortfolio from "./Portfolio/CreatePortfolio.js";
import AddPortToLeague from "./Portfolio/AddPortToLeague.js";
import PortfolioPage from "./Portfolio/PortfolioPage.js";
import MyPortfoliosPage from "./Portfolio/MyPortfoliosPage.js";
import CreateLeaguePage from "./League/CreateLeaguePage.js";
import JoinLeaguePage from "./League/JoinLeaguePage.js";
import MyLeaguePage from "./League/MyLeaguesPage.js";
import RestrictedTable from "./RestrictedTable.js";
import ProtectedRoute from "./RouterHelpers/ProtectedRoute.js";
import PublicOnlyRoute from "./RouterHelpers/PublicOnlyRoute.js";
import AdminRoute from "./RouterHelpers/AdminRoute.js";

import './App.css';
import AdminPost from "./Admin/AdminPost.js";
import AdminEmail from "./Admin/AdminEmail.js";

import { Routes, Route } from "react-router-dom";
import MarketNotFound from "./RouterHelpers/MarketNotFound.js";
import PageNotFound from "./RouterHelpers/PageNotFound.js";
import SignUpPage from "./SignUpPage.js";
import LogInPage from "./LogInPage.js";
import ForgotPasswordPage from "./ForgotPasswordPage.js";
import ResetPasswordPage from "./ResetPasswordPage.js";

const Body = (props) => {






  /*const componentMap = {
    "": null,
    Home: <BodyHome />,
    CreatePortfolio: <Portfolio type={"CreatePortfolio"} portCallback={updatePortandGo}/>,
    AddPortToLeague: <Portfolio type={"AddPortToLeague"} portCallback={updatePortandGo}/>,
    MyPortfolios: <Portfolio type={"MyPortfolios"} portCallback={updatePortandGo}/>,
    Portfolio: <Portfolio type={"Portfolio"} portCallback={updatePortandGo}/>,
    Portfolios: <Portfolio type={"Portfolios"} portInfo={portInfo} leagueCallback={updateLeagueandGo}/>,
    League: <League type={"League"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    CreateLeague: <League type={"CreateLeague"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    JoinLeague: <League type={"JoinLeague"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    MyLeagues: <League type={"MyLeagues"} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    Leagues: <League type={"Leagues"} leagueInfo={leagueInfo} portCallback={updatePortandGo} leagueCallback={updateLeagueandGo}/>,
    Market: <Market year={2024} hist={false} type="Reg" sport="NFL" transact={false}/>,
    GlobalMarket: <div> Market<Market year={2024} transact={false}/> </div>,
    NCAAMMarket: <Market year={2024} hist={false} type="Playoffs" sport="NCAAM" transact={false}/>,
    NFLMarket: <Market year={2024} hist={false} type="Reg" sport="NFL" transact={false}/>,
    NFLPlayoffMarket: <Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={false}/>,
    NBAmarket: <Market year={2024} hist={false} sport="NBA" transact={false}/>,
    Restricted: <RestrictedTable type="Reg" sport="NFL" />,
    Restricted: <RestrictedTable type="Playoffs" sport="NFL" />,
    RestrictNFLMarket: <RestrictedNFLTable type="Reg"/>,
    RestrictNFLPlayoffMarket: <RestrictedNFLTable type="Playoffs"/>,

    NFLMarket2023: <Market year={2023} hist={true} type="Reg" sport="NFL" transact={false}/>,
    NFLPlayoffMarket2023: <Market year={2023} hist={true} type="Playoffs" sport="NFL" transact={false}/>,

    News: <News />,
    Admin: <Admin />,
    AdminUniversal: <Admin />,
    AdminStock: <AdminStock />,
    AdminGame: <AdminGame />,
    AdminPost: <AdminPost />,
    AdminEmail: <AdminEmail />
  };

  */





  return (
    <div>
      <div class="column side">
          <SiteNews />
      </div>
      <div class="column middle">
      <Routes>
            {/* Public Routes */}
            <Route path="/" element={<About />} />
            <Route path="/home" element={<About/>} />
            <Route path="/about" element={<About />} />

            {/* Auth Routes - Only accessible when NOT logged in */}
            <Route element={<PublicOnlyRoute />}>
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/signup/:redirectUrl?" element={<SignUpPage />} />
              <Route path="/login" element={<LogInPage />} />
              <Route path="/login/:redirectUrl?" element={<LogInPage />} />
              <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="/forgotpassword/:redirectUrl?" element={<ForgotPasswordPage />} />
              <Route path="/resetpassword" element={<ResetPasswordPage />} />
              <Route path="/resetpassword/:redirectUrl?" element={<ResetPasswordPage />} />
            </Route>

            {/* Market Routes */}
            <Route path="/market">
              <Route index element={<Market year={2024} hist={false} type="Reg" sport="NFL" transact={0}/>} />
              <Route path="NFL">
                <Route index element={<Market year={2024} hist={false} type="Reg" sport="NFL" transact={0}/>} />
                <Route path="2024" element={<Market year={2024} hist={false} type="Reg" sport="NFL" transact={0}/>} />
                <Route path="2023" element={<Market year={2023} hist={true} type="Reg" sport="NFL" transact={0}/>} />
                <Route path="*" element={<MarketNotFound />} />
              </Route>
              <Route path="NFLPlayoffs">
                <Route index element={<Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={0}/>} />
                <Route path="2024" element={<Market year={2024} hist={false} type="Playoffs" sport="NFL" transact={0}/>} />
                <Route path="2023" element={<Market year={2023} hist={true} type="Playoffs" sport="NFL" transact={0}/>} />
                <Route path="*" element={<MarketNotFound />} />
              </Route>
              <Route path="MLB">
                <Route index element={<Market year={2025} hist={false} type="Reg" sport="MLB" transact={0}/>} />
                <Route path="2025" element={<Market year={2025} hist={false} type="Reg" sport="MLB" transact={0}/>} />
              </Route>
              <Route path="NCAAM">
                <Route index element={<Market year={2025} hist={false} type="Playoffs" sport="NCAAM" transact={0}/>} />
                <Route path="2025" element={<Market year={2025} hist={false} type="Playoffs" sport="NCAAM" transact={0}/>} />
                <Route path="2024" element={<Market year={2024} hist={true} type="Playoffs" sport="NCAAM" transact={0}/>} />
                <Route path="*" element={<MarketNotFound />} />
              </Route>
              <Route path="restricted" element={<RestrictedTable type="Playoffs" sport="NCAAM" />} />
              <Route path="*" element={<MarketNotFound />} />
            </Route>

            {/* Protected Routes - Only accessible when logged in */}
            <Route element={<ProtectedRoute />}>
              {/* Portfolio Routes */}
              <Route path="/portfolio">
                <Route path="create" element={<CreatePortfolio />} />
                <Route path="add" element={<AddPortToLeague />} />
                <Route path=":id" element={<PortfolioPage />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route path="/portfolios" element={<MyPortfoliosPage />} />

              {/* League Routes */}
              <Route path="/league">
                <Route path="create" element={<CreateLeaguePage />} />
                <Route path="join/:id" element={<JoinLeaguePage />} />
                <Route path="join" element={<JoinLeaguePage />} />
                <Route path=":id" element={<League />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route path="/leagues" element={<MyLeaguePage />} />

              {/* Admin Routes - Only accessible when logged in AND admin */}
              <Route element={<AdminRoute />}>
                <Route path="/admin">
                  <Route index element={<Admin />} />
                  <Route path="universal" element={<Admin />} />
                  <Route path="stock" element={<AdminStock />} />
                  <Route path="post" element={<AdminPost />} />
                  <Route path="email" element={<AdminEmail />} />
                  <Route path="game" element={<AdminGame />} />
                  <Route path="*" element={<PageNotFound />} />
                </Route>
              </Route>
            </Route>

            {/* Catch-all route for any unmatched paths */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
      </div>
      <div class="column side"></div>
    </div>
  );
}

export default Body;
