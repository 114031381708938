import React from "react";

import { useLocation, useNavigate } from 'react-router-dom';

import { Menu } from "antd";
const { SubMenu } = Menu;

const NavigationMenu = (props) => {
    //const menu = ();

    const navigate = useNavigate();
    const location = useLocation();

    const isAdmin = props.isAdmin;
    const usrSignedIn = props.usrSignedIn;
    const isTabletOrMobile = props.isTabletOrMobile;


    
      const handleTitleClick = (e) => {
        //console.log(e);
        console.log("handle title click");
        //console.log("Clicked on button: " + e.key);
        if (!isTabletOrMobile){
          //setFrame(e.key);
        }
    
      };

    return (
        <Menu onClick={(e) => {navigate(e.key);}} mode="horizontal" selectedKeys={[location.pathname]}>
          <Menu.Item key="/">Home</Menu.Item>
          <Menu.Item key="/about">About</Menu.Item>
            <SubMenu key="/market" title="Market" onTitleClick={handleTitleClick}>
                {/*<Menu.Item key="GlobalMarket"> Global</Menu.Item>*/}
                <Menu.Item key="/market/NCAAM">NCAAM</Menu.Item>
                <Menu.Item key="/market/NFLPlayoffs">2024 NFL Playoffs</Menu.Item>
                <Menu.Item key="/market/NFL">2024 NFL</Menu.Item>
                <Menu.Item key="/market/restricted">Restricted</Menu.Item>
                <Menu.Item key="/market/NCAAM/2024">2024 NCAAM</Menu.Item>
                <Menu.Item key="/market/NFL/2023">2023 NFL</Menu.Item>
                <Menu.Item key="/market/NFLPlayoffs/2023">2023 NFL Playoffs</Menu.Item>
                {usrSignedIn && isAdmin && <Menu.Item key="RestrictNFLMarket">Restricted Reg</Menu.Item>}
                {usrSignedIn && isAdmin && <Menu.Item key="RestrictNFLPlayoffMarket">Restricted Post</Menu.Item>}
                {/*<Menu.Item key="NBAMarket"> NBA </Menu.Item> */}
            </SubMenu>
            {usrSignedIn && (
            <SubMenu title="Portfolio" key="/portfolios" onTitleClick={(e) => {if (!isTabletOrMobile) navigate(e.key);}}>
                <Menu.Item key="/portfolio/create"> Create Portfolio </Menu.Item>
                <Menu.Item key="/portfolio/add"> Add Portfolio To League </Menu.Item>
                <Menu.Item key="/portfolios"> My Portfolios </Menu.Item>
            </SubMenu>
            )}

            {usrSignedIn && (
            <SubMenu title="League" key="League" onTitleClick={handleTitleClick}>
                <Menu.Item key="/league/create"> Create a League </Menu.Item>
                <Menu.Item key="/league/join"> Join a League </Menu.Item>
                <Menu.Item key="/leagues"> My Leagues</Menu.Item>
                {/*<Menu.Item key="PublicLeages"> Public Leagues</Menu.Item> */}
            </SubMenu>
            )}



            {/*<Menu.Item key="News">News</Menu.Item> */}
            {usrSignedIn && isAdmin && (
            <SubMenu title="Admin" key="/admin" onTitleClick={handleTitleClick}>
            <Menu.Item key="/admin/universal">Universal</Menu.Item>
            <Menu.Item key="/admin/stock">Stock</Menu.Item>
            <Menu.Item key="/admin/post">Post</Menu.Item>
            <Menu.Item key="/admin/email">Email</Menu.Item>
            <Menu.Item key="/admin/game">Game</Menu.Item>
            </SubMenu>)}

        </Menu>
    );
}

export default NavigationMenu;