import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useContext } from "react";
import { getAdminStatus } from "../utils/authUtils";
import AuthContext from "../contexts/Authentication";

const AdminRoute = () => {
    const { auth } = useContext(AuthContext);

    const location = useLocation();

    // Check if user has admin privileges using role from auth object
    if (!getAdminStatus(auth?.role)) {
        // If not admin, redirect to previous page or home, preserving the current location for reference
        return <Navigate to={location.state?.from || "/home"} state={{ from: location.pathname }} replace />;
    }

    // If user is admin, render the child routes
    return <Outlet />;
};

export default AdminRoute; 