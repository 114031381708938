import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../contexts/Authentication';

const PublicOnlyRoute = () => {
  const { auth } = useContext(AuthContext);

  // If user is logged in, redirect to home page
  if (auth?.user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default PublicOnlyRoute; 