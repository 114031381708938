import { Form, InputNumber, Button, Radio} from "antd";
import React, { useEffect,useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import Market from "../Market.js";
import PortSellTable from "./PortSellTable.js";
import { LeagueTypeToTimeframeType, TimeframeTypeCurrentTimeframe } from "../CONSTANTS.js";
//import axios from 'axios';


const PortfolioTrade = (props) => {

  const axiosPrivate = useAxiosPrivate();

  const [isErr,setIsErr] = useState(false);
  const [isSubmitting,setIsSubmitting] = useState(false);
  const [isConfirming,setIsComfirming] = useState(false);
  const [isSuccess,setIsSuccess] = useState(false);
  const [playerData,setPlayerData] = useState({});
  const [isPlayerSelected,setIsPlayerSelected] = useState(false);
  const [errMsg,setErrMsg] = useState("");

  const [tradeType,setTradeType] = useState('buy');

  const [numShares,setNumShares] = useState(0);

  const portHist = !(props.portInfo.port_timeframe === TimeframeTypeCurrentTimeframe[props.portInfo.port_sport_league_id][LeagueTypeToTimeframeType[props.portInfo.port_type]])

  useEffect( () => {
    console.log("here in portfolio trade");
    /*
    console.log(props.portInfo.port_year)
    console.log(props.portInfo.port_sport_league)
    console.log (props.portInfo);
    
    console.log(props.portInfo.port_timeframe)
    console.log(props.portInfo.port_sport_league_id);
    console.log(props.portInfo.port_type);
    console.log (LeagueTypeToTimeframeType[props.portInfo.port_type]);
    console.log( TimeframeTypeCurrentTimeframe[props.portInfo.port_sport_league_id][LeagueTypeToTimeframeType[props.portInfo.port_type]])
    console.log (!(props.portInfo.port_timeframe === TimeframeTypeCurrentTimeframe[props.portInfo.port_sport_league_id][LeagueTypeToTimeframeType[props.portInfo.port_type]]));

    console.log (portHist);
    */
  }, []); //empty dependency array means function only called once on mount



  /*const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  } */

  const buyStock = (e, record) => {
    console.log(" Port trade buying stock");
    console.log(e);
    console.log(record);
    setIsPlayerSelected(true);
    setIsComfirming(false);
    setPlayerData({name:record.name, price:record.price, pid:record.ent_id, equity_id: record.equity_id})
  }

  const sellStock = (e, record) => {
    console.log(" Port trade selling stock");
    console.log(e);
    console.log(record);
    setIsPlayerSelected(true);
    setIsComfirming(false);
    setPlayerData({name:record.name, price:record.price, pid:record.ent_id, quantity: record.numshares, equity_id: record.equity_id})
  }

  const confirmTransaction = (e) => {
    var num = e.numshares;
    if (tradeType==='sell') num = -1*e.numshares;
    setNumShares(num);
    setIsComfirming(true);
  }

  const executeOrder = async () => {
    console.log("Buying or selling the shares");

    var data = {};
    data.port_id = props.portInfo.port_id;
    data.ent_id = playerData.pid;
    data.equity_id = playerData.equity_id;
    data.numshares = numShares;

    try {
      console.log(data);
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/portfolio/trade',data);
      console.log(response);

      setIsErr(false);
      setIsSuccess(true);
      setIsSubmitting(false);
      setIsComfirming(false);
      props.tradeCallback();

    }
    catch(err){
      console.log(err);
      setIsSuccess(false);
      if (err.response?.data?.message){
        console.log(err.response?.data?.message);
        setErrMsg(err.response?.data?.message);
      }
      else {
        setErrMsg( err);
      }
      setIsErr(true);
      setIsSubmitting(false);
      setIsComfirming(false);
    }
  }


  const executeAddDrop= async () => {
    console.log("Add or drop player");

    var data = {};
    data.port_id = props.portInfo.port_id;
    data.ent_id = playerData.pid;
    data.equity_id = playerData.equity_id;
    data.numshares = 1;
    if (tradeType==='sell') data.numshares= -1;

    try {
      console.log(data);
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/portfolio/trade',data);
      console.log(response);

      setIsErr(false);
      setIsSuccess(true);
      setIsSubmitting(false);
      setIsComfirming(false);
      props.tradeCallback();

    }
    catch(err){
      console.log(err);
      setIsSuccess(false);
      if (err.response?.data?.message){
        console.log(err.response?.data?.message);
        setErrMsg(err.response?.data?.message);
      }
      else {
        setErrMsg( err);
      }
      setIsErr(true);
      setIsSubmitting(false);
      setIsComfirming(false);
    }
  }

  const tryAgain = () => {
    setIsErr(false);
    setNumShares(0);
    setIsSubmitting(false);
    setIsComfirming(false);
  }

  const makeAnotherTransaction = () => {
    setIsPlayerSelected(false)
    setPlayerData({});
    setNumShares(0);
    setIsErr(false);
    setIsSuccess(false);
    setIsComfirming(false);
    setIsSubmitting(false);
  }



  const returnToMarket = () => {
    setTradeType('buy');
    setIsPlayerSelected(false);
    setIsComfirming(false);
    setNumShares(0);
  }

  const [stockView,setStockView] = useState(false);
  const typeMap = {
    1: "Reg",
    2: "Playoffs",
    3: "Both"  //TODO need to code support for marketType Both in Market.js
  };


  return (

    <div>
      {!isPlayerSelected && !stockView &&
        <span>
          <Radio.Group value={tradeType} onChange={(e) => setTradeType(e.target.value)}>
          <Radio.Button value="buy">{props.portInfo.port_format===0 ? "Buy Stock" : "Add"}</Radio.Button>
          <Radio.Button value='sell'>{props.portInfo.port_format===0 ? "Sell Stock" : "Drop"}</Radio.Button>
          </Radio.Group>
        </span>
      }

      {tradeType==='buy' && !isPlayerSelected &&
      <Market type={typeMap[props.portInfo.port_type]} sport={props.portInfo.port_sport_league} year={props.portInfo.port_year} hist={portHist} setStockView={setStockView} transact={props.portInfo.port_format+1} purchaseCallback={buyStock}/>
      }
      {tradeType==='sell' && !isPlayerSelected &&
      <PortSellTable portInfo={props.portInfo} sellCallback={sellStock} setChartView={setStockView}/>
      }
      {isPlayerSelected &&
        <span>
          {tradeType==='buy' &&
            <span>
              {!isSubmitting && !isSuccess && !isErr &&
                <span>
                  <div>{props.portInfo.port_format===0 ? "Purchasing "+playerData.name + " @ $" + playerData.price +" per share." : "Add " + playerData.name}</div>
                  {props.portInfo.port_format===0 ?
                    <Form onFinish={confirmTransaction}>
                      <Form.Item label="Number of Shares" name="numshares" rules={[{ required: true, message: "Enter a number of shares to Buy" }]}>
                        <InputNumber precision={0}/>
                      </Form.Item>
                      <Form.Item>
                        <Button  type="primary" htmlType="submit" >Buy Shares</Button>
                      </Form.Item>
                    </Form>
                  :
                    <div>
                      <Button onClick={executeAddDrop} type="primary" >Confirm</Button>
                    </div>
                  }
                  {isConfirming && <div>
                    <div>Purchasing {numShares} shares of {playerData.name} for a total of ${(numShares*playerData.price).toFixed(2)}</div>
                    <Button onClick={executeOrder}>Submit Trade</Button>
                  </div>}
                  <Button onClick={returnToMarket}> Return to Market </Button>
                </span>
              }
            </span>
          }
          {tradeType==='sell' &&
            <span>
              {!isSubmitting && !isSuccess && !isErr &&
                <span>
                  {(props.portInfo.port_format===0) ?
                    <span>
                      <div>Selling {playerData.name} @ ${playerData.price} per share.</div>
                      <div>Current number of shares: {playerData.quantity}</div>
                    </span>
                  :
                  <div>{"Drop " + playerData.name}</div>}
                  {props.portInfo.port_format===0 ?
                    <Form onFinish={confirmTransaction}>
                      <Form.Item label="Number of Shares" name="numshares" rules={[{ required: true, message: "Enter a number of shares to Sell" }]}>
                        <InputNumber min={1} precision={0}/>
                      </Form.Item>
                      <Form.Item>
                        <Button  type="primary" htmlType="submit" >Sell Shares</Button>
                      </Form.Item>
                    </Form>
                  :
                    <div>
                      <Button onClick={executeAddDrop} type="primary" >Confirm</Button>
                    </div>
                  }
                  {isConfirming && <div>
                    <div>Selling {-1*numShares} shares of {playerData.name} for a total of ${(-1*numShares*playerData.price).toFixed(2)}</div>
                    <Button onClick={executeOrder}>Submit Trade</Button>
                  </div>}
                  <div>
                    <Button onClick={returnToMarket}> Return to Market </Button>
                  </div>
                </span>
              }
            </span>
          }
          {isErr &&
            <span>{errMsg}
              <div>Transaction Failed
                <div><Button  onClick={tryAgain} >Try Again?</Button></div>
              </div>
            </span>}
          {isSuccess &&
            <span>
              <div>Transaction Successful!</div>
              <Button  onClick={makeAnotherTransaction} >Make Another Transaction?</Button>
            </span>}
          {isSubmitting && <div>Transaction Processing</div>}
        </span>
      }
    </div>
  )
}

export default PortfolioTrade;