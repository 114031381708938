import React, { useEffect, useCallback } from "react";
import NFLTable from "./NFLTable";
import TeamOnlyTable from "./Market/TeamOnlyTable";

const Market = (props) => {



  const updateChartView = useCallback( (value) => {
    if (props.setStockView) {
      props.setStockView(value);
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    //console.log("here in market");
    updateChartView(false);
  }, [props.type, props.sport, props.year, props.hist, updateChartView]);



  const backFunction = () => {
    updateChartView(false);
  }

  const showStockPerformance = (equity_id, name) => {
    updateChartView(true);
  }



  const componentMap = {
    "": <NFLTable  type="Reg" showStockPerformance={showStockPerformance} backFunction={backFunction} transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
    NFL: <NFLTable type={props.type} year={props.year} hist={props.hist} showStockPerformance={showStockPerformance} backFunction={backFunction} transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
    MLB: <TeamOnlyTable sport={props.sport} type={props.type} year={props.year} hist={props.hist} showStockPerformance={showStockPerformance} backFunction={backFunction} transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
    NCAAM: <TeamOnlyTable sport={props.sport} type={props.type} year={props.year} hist={props.hist} showStockPerformance={showStockPerformance} backFunction={backFunction} transact={props.transact} purchaseCallback={props.purchaseCallback}/>,
  };






  return (
    <div>
      {componentMap[props.sport]}
    </div>
  )
}

export default Market;
