import React, {useState} from "react";
import axios from "./axios";

import {Button, Form, Input} from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { validateRedirectPath } from './utils/pathValidation';

const SignUpPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState("");

  // Get and validate the redirect path from state
  const from = validateRedirectPath(location.state?.from);

  const submitSignUp = async (e) => {
    console.log("Handle sign up Submit");
    //console.log(e);
    //e.preventDefault();
    console.log("Username is: " + e.username);
    console.log("Referral code is: " + e.refCode);
    try {
      const response = await axios.post("/signup",
        //JSON.stringify({ user, pwd: passw }),
        JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //const token = response?.data?.token;
      //const access = response?.data?.roles;
      console.log(response);
      //setAuth({user, passw, access, token});
      //setusrSignedIn(true);
      // setusrname(user);
      //potential future feature: call login path on db to auto login
      //showLogin("Please login to complete signup");

      // After successful signup, redirect to login with the same redirect destination
      navigate("/login", { state: { from }, replace: true });

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
      else {
        console.log(err.response);
        console.log(err.response.status);
        console.log(err.response.data.message);
        setErrMsg(err?.response?.data?.message);
/*           if (err.response?.status === 400){
          setErrMsg('Missing username or password');
        }
        else if (err.response?.status === 401){
          setErrMsg('Wrong password');
        }
        else if (err.response?.status === 401){
          setErrMsg('Wrong password');
        }
        else {
          setErrMsg("Other Error");
        } */
      }
    }


  };

  return (
    <div>
      <Form onFinish={submitSignUp}>
        <Form.Item label="Username" name="username" rules={[{ required: true, message: "Username required" }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="pwd"
          rules={[{ required: true, message: "Password required" }]}
            
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name = "confirm"
          label="Verify Password"
          rules={[ {required:true},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('pwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Password does not match!'));
              },
            }),
          ]}
          dependencies={["pwd"]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item label="First Name" name="fname" rules={[{ required: true, message:'First name required'  }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name = "lname" rules={[{ required: true, message:'Last name required' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Not a valid E-mail." }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Referral Code" name="refCode">
          <Input />
        </Form.Item>
        <Form.Item label="Phone Number" name="phone" hidden={true}>
          <Input />
        </Form.Item>
        <div>{errMsg}</div>
        <Form.Item>
          <Button type="primary" htmlType="submit">Sign Up</Button>
          <div>
            Already Have an Account? Log In <Link to="/login" state={{ from }}>here</Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignUpPage;