import React, {useState} from "react";
import axios from "./axios";
import { Button, Form, Input } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { validateRedirectPath } from './utils/pathValidation';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errMsg, setErrMsg] = useState("");

  // Get and validate the redirect path from state
  const from = validateRedirectPath(location.state?.from);

  // Get the email from state if available
  //const email = location.state?.email || '';

  const submitResetPass = async (e) => {
    try {
      await axios.post('/forgotPassword/update',
        JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // After successful password reset, redirect to login with success message
      navigate("/login", {
        state: {
          from,
          message: "Password reset successful. Please login with your new password."
        },
        replace: true
      });

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrMsg('Incorrect Security Code');
      } else if (err.response?.status === 422) {
        setErrMsg('Security Code Expired');
      } else {
        setErrMsg(err?.response?.data?.message || "Error resetting password");
      }
    }
  };

  return (
    <div>
        <Form onFinish={submitResetPass}>
          <Form.Item label="Username or email" name="username" rules={[{ required: true, message: "Username/email required"  }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Security Key" name="token" rules={[{ required: true, message:'Security key required'  }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="pwd"
            rules={[{ required: true, message: "Password required" }]}
              
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name = "confirm"
            label="Verify New Password"
            rules={[ {required:true},
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('pwd') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password does not match!'));
                },
              }),
            ]}
            dependencies={["pwd"]}
          >
            <Input.Password />
          </Form.Item>
          <div color='red'>{errMsg}</div>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit New Password</Button>
            <div>
              Need a Security Code? Click <Link to={"/forgotpassword/"} state={{ from }}>here</Link>
            </div>
          </Form.Item>
        </Form>
    </div>
  );
};

export default ResetPasswordPage;