import React, {useState} from "react";
import axios from "./axios";
import { Button, Form, Input } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { validateRedirectPath } from './utils/pathValidation';

const ForgotPasswordPage = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const [errMsg, setErrMsg] = useState("");

  // Get and validate the redirect path from state
  const from = validateRedirectPath(location.state?.from);

  const submitForgotPassword = async (e) => {
    try {
      console.log(e);
      await axios.post('/forgotPassword',JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // After successful request, redirect to reset password with the same redirect destination
      navigate("/resetpassword", {
        state: {
          from,
          email: e.email // Pass email to reset page for better UX
        },
        replace: true
      });

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else {
        setErrMsg(err?.response?.data?.message || "Error sending reset code");
      }
    }
  };

  return (
    <div>
      <Form onFinish={submitForgotPassword}>
        <Form.Item label="Username or email" name="username" rules={[{ required: true, message: "Username/email required"  }]}>
          <Input />
        </Form.Item>
        <div>If an account with this username or email exists, you will receive an email with a code to update your password.</div>
        <div color='red'>{errMsg}</div>
        <Form.Item>
          <Button type="primary" htmlType="submit">Request Security Code</Button>
          {<div>
            Already have your Security Code? Click <Link to={"/resetpassword/"} state={{ from }}>here</Link>
          </div>}
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPasswordPage;