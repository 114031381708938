import {Checkbox, Form, Input, InputNumber, Radio, Button, Table} from "antd";
import React, { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { Link } from "react-router-dom";

import { useMediaQuery } from 'react-responsive';
import { PORTFOLIO_FORMAT, ROSTER_FORMAT } from "../CONSTANTS.js";


const defaultPortRegSeasonSettings = {
  budget: 100000,
  inseason: true,
  shorting: true,
  delayCosts: true,
  isTransactionCost: true,
  transType: "global",
  transcostfixed: 10.0,
  transcost_percent: 0.5,
  transcost_pershare: 0.0
}

const defaultPortPlayoffSettings = {
  budget: 100000,
  inseason: true,
  shorting: true,
  isTransactionCost: false,
  transcostfixed: 0.0,
  transcost_percent: 0.0,
  transcost_pershare: 0.0
}

const defaultRosterPlayoffSettings = {
  inseason: true,
  numQB: 1,
  numRB: 2,
  numWR: 2,
  numTE: 1,
  numK:  1,
  numTeam: 1,
  numBench: 0,
  numIR: 0,
  teamPlayersRestricted: false,
  playoffMultipliers: 1

}

const defaultRosterPlayoffAllTeamSettings = {
  inseason: false,
  numQB: 2,
  numRB: 4,
  numWR: 4,
  numTE: 2,
  numK:  1,
  numTeam: 1,
  numBench: 0,
  numIR: 0,
  teamPlayersRestricted: true,
  maxteamplayers: 1,
  playoffMultipliers: 0

}

const defaultNCAAMPortSettings = {
  budget: 100000,
  inseason: true,
  shorting: true,
  isTransactionCost: false,
  transcostfixed: 0.0,
  transcost_percent: 0.0,
  transcost_pershare: 0.0
}

const defaultNCAAMRosterSettings = {
  maxteamplayers:1,
  inseasonTrading: false

}

const CreateLeaguePage = (props) => {


  const axiosPrivate = useAxiosPrivate();

  const isXSmall = useMediaQuery({maxWidth:575});

  //switch these when playoffs are default
  const [isTransactionCost,setIsTransactionCost] = useState(false); //true for Reg, false for playoffs
  const [seasonType,setSeasonType] = useState("Playoffs");  //switch between default for "Reg" and "Playoffs"

  //switch this when changing sport
  //const [leagueSport,setLeagueSport] = useState("NFL");
  const [leagueSport,setLeagueSport] = useState("NCAAM");

  const [leaguePrivacy,setLeaguePrivacy] = useState(false);
  const [leagueFormat,setLeagueFormat] = useState("portfolio");

  const [numPlayersNeeded,setNumPlayersNeeded] = useState(false);

  const [leagueTransactionType,setLeagueTransactionType] = useState("global");
  const [delayCosts,setDelayCosts] = useState(true);
  const [lateJoin,setLateJoin] = useState(true);
  const [shorting,setShorting] = useState(true);
  const [inseasonTrading,setInseasonTrading] = useState(true);
  const [playoffMultipliers,setPlayoffMultipliers] = useState(false);
  const [numEntriesRestricted,setNumEntriesRestricted] = useState(false);
  const [totalEntriesRestricted,setTotalEntriesRestricted] = useState(false);
  const [teamPlayersRestricted,setTeamPlayersRestricted] = useState(false);

  const [allDefaultAgreement,setAllDefaultAgreement] = useState(true);
  const [defaultEnabled, setDefaultEnabled] = useState(true);

  const [isSubmitting,setIsSubmitting] = useState(false);
  const [isCompleted,setIsCompleted] = useState(false);
  const [isErr,setIsErr] = useState(false);
  const [errMsg,setErrMsg] = useState("");
  const [createdLgInfo,setCreatedLgInfo] = useState({});
  const [LgJoinURL,setLgJoinURL] = useState("");

  const [budget,setBudget] = useState(100000);

  const createLeagueClick = async (e) => {
    console.log("here in create league submit");
    console.log(e);

    var data = e;
    data.private = leaguePrivacy;

    if (!e?.budget){
      data.budget = 0;
    }

    if (!e?.transcost){
      data.transcost = 0;
    }


    if (leagueSport==='NFL'){
      data.sport = 1;
      data.sport_league = 1;
    }
    else if (leagueSport==="NCAAM"){
      data.sport = 2;
      data.sport_league = 6;
    }
    else{
      data.sport = -1;
      data.sport_league = -1;
    }

    data.season_type = -1;
    if (seasonType==="Reg"){
      data.season_type = 2;
    }
    else if (seasonType==="Playoffs"){
      data.season_type = 3;
    }
    else if (seasonType==="Both"){
      data.season_type = -2;
    }


    if (!e?.numplayers){
      data.numplayers = -1;
    }

    if (!e?.maxentries){
      data.maxentries = -1;
    }

    if (!e?.maxtotentries){
      data.maxtotentries=-1;
    }

    if (!e?.maxteamplayers){
      data.maxteamplayers=-1;
    }

    data.playoffMultipliers = 0;
    data.delay = 0; //dont delay
    data.shorting = 0;
    data.options=0;
    data.transactionType = 0;

    data.format = -1;
    if (leagueFormat==='roster'){
      data.format = ROSTER_FORMAT;
      data.cashless = true;
      data.dividendsonly = true;
      data.maxsharesperplayer = 1;
      data.scarcity = -1;
      if (seasonType==="Playoffs"){
        if (playoffMultipliers){
          data.playoffMultipliers = 1;
        }

        if (leagueSport==="NCAAM"){
          data.maxteamplayers = 1; //used to indicate 1 team per seed
        }
        else{
          data.roster = !teamPlayersRestricted ? PCrosterData: StackrosterData;
        }

      }
    }
    else if (leagueFormat==='portfolio'){
      data.format = PORTFOLIO_FORMAT;
      data.cashless = false;
      data.dividendsonly = false;
      data.maxsharesperplayer = -1;
      data.scarcity = -1;
      if (delayCosts){
        data.delay = 1; //delay
      }
      if (shorting){
        data.shorting = 1;
      }
      if (isTransactionCost){
        if (leagueTransactionType==='global'){
          data.transactionType = 1;
        }
        else if (leagueTransactionType==='percent'){
          data.transactionType = 2;
        }
        else if (leagueTransactionType==='pershare'){
          data.transactionType = 4;
        }
        else if (leagueTransactionType==='pertrans'){
          data.transactionType = 3;
        }
        
      }
    }

    data.late_join = 0; //dont allow late join
    if (lateJoin){
      data.late_join = 1; //allow late join
    }

    data.inseason = 0
    if (inseasonTrading){
      data.inseason = 1;
    }
  
    console.log(data);

    try {
      setIsErr(false);
      setIsSubmitting(true);
      const response = await axiosPrivate.post('/league/create',data);
      console.log(response);
      if (response?.data?.league_id){
        const lg_info = {
          league_id: response.data.league_id,
          league_name: e.league_name,
          lg_type: data.seasonType>0 ? Math.abs(data.seasonType)-1:3,
          week_start: data.seasonType===3 ? -1: 0,
          week_end: Math.abs(data.seasonType)===2 ? -2: -1,
          sport: e.sport
        };
        setCreatedLgInfo(lg_info);
        setLgJoinURL("www.spoxmarket.com/league/join/" + String(lg_info.league_id));
      }

      setIsCompleted(true);
      setIsSubmitting(false);
      setIsTransactionCost(false);
      setNumEntriesRestricted(false);
      setNumPlayersNeeded(false);
      setTotalEntriesRestricted(false);
      setLeaguePrivacy(false);
      console.log("league create successful");

    }
    catch(err){
      console.log(err);
      console.log(err.response);
      if (err.response?.data?.message){
        console.log(err.response?.data?.message);
        setErrMsg("League Create Failed: " + err.response?.data?.message);
      }
      else {
        setErrMsg("League Create Failed: " + err.message);
      }
      setIsErr(true);
      setIsSubmitting(false);
    }
  }

  /*const privacyOnChage = (e) => {
    setLeaguePrivacy(e.target.value);
  } */

  const NFLrosterColumns = [
    {
      title: isXSmall ? "Pos" : "Position",
      dataIndex: "pos",
      key: "pos"
    },
    {
      title: isXSmall ? "#" : 'Quantity',
      dataIndex: 'quant',
      key: 'quant'
    }
  ];

  const PCrosterData = [
    {
      pos: 'QB',
      quant: 1
    },
    {
      pos: 'RB',
      quant: 2
    },
    {
      pos: 'WR',
      quant: 2
    },
    {
      pos: 'TE',
      quant: 1
    },
    {
      pos: 'K',
      quant: 1
    },
    {
      pos: 'TEAM',
      quant: 1
    },
    {
      pos: 'Bench',
      quant: 0
    },
    {
      pos: 'IR',
      quant: 0
    },

  ];

  const StackrosterData = [
    {
      pos: 'QB',
      quant: 2
    },
    {
      pos: 'RB',
      quant: 4
    },
    {
      pos: 'WR',
      quant: 4
    },
    {
      pos: 'TE',
      quant: 2
    },
    {
      pos: 'K',
      quant: 1
    },
    {
      pos: 'TEAM',
      quant: 1
    },
    {
      pos: 'Bench',
      quant: 0
    },
    {
      pos: 'IR',
      quant: 0
    },

  ];

  const setDefaultInvalid = () => {
    setAllDefaultAgreement(false);
    setDefaultEnabled(false);
  }

  const setDefaultLocked = () => {
    setAllDefaultAgreement(true);
    setDefaultEnabled(true);
  }

  const checkOnChange = () => {

    console.log("checking change")
    console.log(leagueFormat)

    if (leagueSport==="NFL"){
      if (leagueFormat==="portfolio"){
        if (inseasonTrading!==defaultPortRegSeasonSettings.inseason){
          setDefaultInvalid()
          return;
        }
        if (delayCosts!==defaultPortRegSeasonSettings.delayCosts){
          setDefaultInvalid();
          return;
        }
        if (budget!==defaultPortRegSeasonSettings.budget){
          setDefaultInvalid()
          return;
        }
        if (shorting!==defaultPortRegSeasonSettings.shorting){
          setDefaultInvalid()
          return;
        }
        if (isTransactionCost!==defaultPortRegSeasonSettings.isTransactionCost){
          setDefaultInvalid()
          return;
        }
        if (leagueTransactionType!==defaultPortRegSeasonSettings.transType){
          setDefaultInvalid()
          return;
        }
        //add the categories of transaction type
        setDefaultEnabled(true);



      }
      else if (leagueFormat==="roster"){

      }
    }
    else if (leagueSport==="NCAAM"){
      if (leagueFormat==="portfolio"){
        if (inseasonTrading!==defaultPortRegSeasonSettings.inseason){
          setDefaultInvalid()
          return;
        }
        if (delayCosts!==defaultPortRegSeasonSettings.delayCosts){
          setDefaultInvalid();
          return;
        }
        if (budget!==defaultPortRegSeasonSettings.budget){
          setDefaultInvalid()
          return;
        }
        if (shorting!==defaultPortRegSeasonSettings.shorting){
          setDefaultInvalid()
          return;
        }
        if (isTransactionCost!==defaultPortRegSeasonSettings.isTransactionCost){
          console.log("istransaction cost not matching")
          setDefaultInvalid()
          return;
        }
        console.log("here2")
        setDefaultEnabled(true);
      }
      else { //roster
        if (inseasonTrading!==defaultPortRegSeasonSettings.inseason){
          setDefaultInvalid()
          return;
        }
        setDefaultEnabled(true);
      }

    }
  }


  const defaultSettingsMap = (league,season,format,rosterRestriction) => {
    //console.log(league)
    //console.log(season)
    //console.log(format)
    //console.log(rosterRestriction)
    if (league==="NCAAM"){
      if (format==="portfolio"){
        return defaultNCAAMPortSettings;
      }
      else if (format==="roster"){
        return defaultNCAAMRosterSettings;
      }
      else {
        return;
      }

    }
    else {
      if (season==="Reg"){
        return defaultPortRegSeasonSettings;
      }
      else {
        if (format==="Portfolio"){
          return defaultPortPlayoffSettings;
        }
        else {
          if (rosterRestriction){
            return defaultRosterPlayoffAllTeamSettings
          }
          else {
            return defaultRosterPlayoffSettings;
          }
        }
      }
    }
  }

  const formatOnChange = (e) => {

    const newFormat = e.target.value;

    if (seasonType==='Playoffs' && leagueSport==="NFL" && newFormat==='roster'){
      setPlayoffMultipliers(true);
    }
    else {
      setPlayoffMultipliers(false);
    }

    if (newFormat!=="portfolio"){
      setLeagueTransactionType("free");
      setIsTransactionCost(false);
    }

    if (newFormat==='portfoliio' || (newFormat==='roster' && leagueSport==="NFL")){
      setInseasonTrading(true);
    }
    else if (leagueSport==="NCAAM" && newFormat==='roster'){
      setInseasonTrading(false);
    }

    if (newFormat!=="portfolio" && newFormat!=='roster'){
      setNumPlayersNeeded(true);
    }
    else{
      setNumPlayersNeeded(false);
    }

    setLeagueFormat(newFormat);

    setDefaultLocked();
  }

  const numPlayersOnChange = (e) => {
    setNumPlayersNeeded(e.target.value);
  }

  const numMaxEntriesOnChange = (e) => {
    setNumEntriesRestricted(e.target.value);
  }

  const numTotEntriesOnChange = (e) => {
    setTotalEntriesRestricted(e.target.value);
  }

  const numTeamPlayersOnChange = (e) => {
    setTeamPlayersRestricted(e.target.value);
  }

  const seasonTypeOnChange = (e) => {
    setSeasonType(e.target.value);
    if (e.target.value==='Playoffs' && leagueFormat==='roster'){
      setPlayoffMultipliers(true);
    }
    else {
      setPlayoffMultipliers(false);
    }

    checkOnChange();
  }

  const sportOnChange = (e) => {
    console.log(e.target.value);
    setLeagueSport(e.target.value)
    //todo set defaults based on chosen sport and format+season
    checkOnChange();
  }

  const transactionTypeOnChange = (e) => {
    setLeagueTransactionType(e.target.value);
  }

  const isTransactionCostOnChange = (e) => {
    const newIsTransactionCost = e.target.checked;
    //console.log(newIsTransactionCost)
    setIsTransactionCost(newIsTransactionCost);

    const defaultSettings = defaultSettingsMap(leagueSport,seasonType,leagueFormat,teamPlayersRestricted);
    console.log(defaultSettings)
    if (defaultSettings.isTransactionCost!==newIsTransactionCost){
      setDefaultInvalid();
    }
    else {
      checkOnChange();
    }
  }

  const shortingOnChange = (e) => {
    const newShorting = e.target.checked;
    //console.log(newShorting)
    setShorting(newShorting);

    const defaultSettings = defaultSettingsMap(leagueSport,seasonType,leagueFormat,teamPlayersRestricted);
    console.log(defaultSettings)
    if (defaultSettings.shorting!==newShorting){
      setDefaultInvalid();
    }
    else {
      checkOnChange();
    }

  }

  const inSeasonOnChange = (e) => {
    const newInSeason = e.target.checked;
    //console.log(newInSeason)
    setInseasonTrading(newInSeason);

    const defaultSettings = defaultSettingsMap(leagueSport,seasonType,leagueFormat,teamPlayersRestricted);
    console.log(defaultSettings)
    if (defaultSettings.inseason!==newInSeason){
      setDefaultInvalid();
    }
    else {
      checkOnChange();
    }
  }

  const budgetOnChange = (e) => {
    //console.log(e)
    const newBudget = e;
    //console.log(newBudget)
    setBudget(newBudget)

    const defaultSettings = defaultSettingsMap(leagueSport,seasonType,leagueFormat,teamPlayersRestricted);
    console.log(defaultSettings)
    if (defaultSettings.budget!==newBudget){
      setDefaultInvalid();
    }
    else {
      checkOnChange();
    }
  }

  /*
  const setIsCompletedFalse = (e) => {
    setIsCompleted(false);
  }
    */


  const tryAgain = () => {
    setLeagueTransactionType("global");
    setNumEntriesRestricted(false);
    setNumPlayersNeeded(false);
    setTotalEntriesRestricted(false);
    setCreatedLgInfo({});
    setLgJoinURL("")
    setErrMsg("");
    setIsErr(false);
  }

  const layout = {
    labelCol: {span:6},
    wrapperCol: {span:14}
  }

  //console.log("creating league page");
  return (

    <div>
      {(isSubmitting) &&
      <div>Creating League...</div>
      }
      {(!isSubmitting &&  !isCompleted && !isErr) &&
    <Form {...layout}  onFinish={createLeagueClick}>
      <Form.Item label="League Name" name="leaguename" rules={[{ required: true, message: "League name required." }]}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Checkbox onChange={(e) => setLeaguePrivacy(e.target.checked)}>Private</Checkbox>
      </Form.Item>
      {leaguePrivacy===true && <Form.Item label="League Password" name="leaguepass" rules={[{ required: true, message: "Provide a password or uncheck private league setting." }]}>
        <Input />
      </Form.Item>}
      <Form.Item label="Sport" >
        <Radio.Group value={leagueSport} onChange={(sportOnChange)} >
          {/*<Radio value="NFL">NFL</Radio>*/}
          {<Radio value="NCAAM">NCAAM</Radio>}
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Season Type"> 
            <Radio.Group value={seasonType} onChange={(seasonTypeOnChange)}>
              {0 && leagueSport==="NFL" && <Radio value="Reg">Regular Season</Radio>}
              {<Radio value="Playoffs">Playoffs</Radio>}
            </Radio.Group>
          </Form.Item>
      {(seasonType==="Playoffs" && <Form.Item label="Format:">
        <Radio.Group value={leagueFormat} onChange={(formatOnChange)}>
          <Radio value="portfolio">Stock Based</Radio>
          {leagueSport==="NFL" && <Radio value="roster">Roster Based</Radio>}
          {/* <Radio value="draft">Draft Based</Radio>
          <Radio value="classic">Classic</Radio>
          <Radio value="auction">Auction</Radio>
          <Radio value="scarcity">Scarcity</Radio>
          <Radio value="bestball">Best Ball</Radio> */}
        </Radio.Group>
      </Form.Item>)}
      {(leagueFormat==="portfolio" || leagueFormat==="roster") &&
        <Form.Item label="Limit Number of People?"> 
          <Radio.Group onChange={numPlayersOnChange} value={numPlayersNeeded}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      }
      {(numPlayersNeeded &&
        <Form.Item label="Max Number of People" name="numplayers" rules={[{ required: true, message: "Enter a max number of players in league" }]}>
          <InputNumber min={1} precision={0} />
        </Form.Item>)
      }
      {(leagueFormat==="portfolio" || leagueFormat==="roster") &&
        <Form.Item label="Limit Number of Entries Per Person?"> 
          <Radio.Group onChange={numMaxEntriesOnChange}  value={numEntriesRestricted}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      }
      {(numEntriesRestricted &&
        <Form.Item label="Max Number of Entries" name="maxentries" rules={[{ required: true, message: "Enter a max number of entries per player." }]}>
          <InputNumber min={1} precision={0} />
        </Form.Item>)
      }
      {(leagueFormat==="portfolio" || leagueFormat==="roster") &&
        <Form.Item label="Limit Total Number of Entries in League?"> 
          <Radio.Group onChange={numTotEntriesOnChange}  value={totalEntriesRestricted}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      }
      {(totalEntriesRestricted &&
        <Form.Item label="Max Total Number of Entries" name="maxtotentries" rules={[{ required: true, message: "Enter a max number of entries for the league." }]}>
          <InputNumber min={1} precision={0} />
        </Form.Item>)
      }
      {(leagueFormat==="portfolio" &&
        <Form.Item initialValue={100000} label="Starting Budget" name="budget" rules={[{ required: true, message: "Budget required." }]}>
          <InputNumber onChange={budgetOnChange} precision={0} step={1} style={{width:150}} defaultValue={100000} addonBefore="$" formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} min={1000} />
        </Form.Item>
      )}
      {(leagueFormat==="portfolio")  &&
          (<Form.Item>
            <Checkbox checked={isTransactionCost} onChange={isTransactionCostOnChange}>Include Transaction Cost</Checkbox>
          </Form.Item>)

        }

        {((isTransactionCost && leagueFormat==="portfolio") && 
          <Form.Item label="Transaction Type"> 
            <Radio.Group defaultValue="global" onChange={(transactionTypeOnChange)}>
              <Radio value="global">Global ($10 + 0.5%)</Radio>
              <Radio value="percent">Percentage of Transaction</Radio>
              <Radio value="pershare">Fixed $ Per Share</Radio>
              <Radio value="pertrans">Fixed $ Per Transaction</Radio>
            </Radio.Group>
          </Form.Item>

        )}
        {((isTransactionCost && leagueFormat==="portfolio" && leagueTransactionType==="percent") &&
            <Form.Item label="Transaction Cost" name="transcost" rules={[{ required: true, message: "Enter a cost or deselect the 'Include Transaction Cost' option." }]}>
              {/*<InputNumber formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} min={0} max={100}/>*/}
              {<InputNumber addonAfter="%" precision={2} step={0.1} min={0} max={100}/>}
            </Form.Item>

        )}
        {((isTransactionCost && leagueFormat==="portfolio" && leagueTransactionType!=="percent" && leagueTransactionType!=="global") &&
            <Form.Item label="Transaction Cost" name="transcost" rules={[{ required: true, message: "Enter a cost or deselect the 'Include Transaction Cost' option." }]}>
              <InputNumber addonBefore="$" precision={2} formatter={value => `${value}`} parser={value => value.replace('$','')} min={0}/>
            </Form.Item>

        )}
        {((isTransactionCost && leagueFormat==="portfolio") && 
          <Form.Item>
            <Checkbox checked={delayCosts} onChange={(e) => setDelayCosts(e.target.checked)}>Delay Costs Until Season Starts</Checkbox>
          </Form.Item>


        )}
        {((leagueFormat==="portfolio" || leagueFormat==="roster") && 
          <span>
            <Form.Item>
              <Checkbox checked={inseasonTrading} onChange={inSeasonOnChange}>Allow In-Season Trading</Checkbox>
            </Form.Item>
          </span>
        )}
        {((leagueFormat==="portfolio") && 
          <span>
            <Form.Item>
              <Checkbox checked={shorting} onChange={shortingOnChange}>Allow Shorting (Negative Shares)</Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox checked={lateJoin} onChange={(e) => setLateJoin(e.target.checked)}>Allow Players to Join After Season Starts</Checkbox>
            </Form.Item>
          </span>
        )}

      {(leagueFormat==='roster' && 
        <span>
          {leagueSport==="NFL" &&
          <span>
            {seasonType==='Playoffs' &&
            <Form.Item>
              <Checkbox checked={playoffMultipliers} onChange={(e) => setPlayoffMultipliers(e.target.checked)}>Playoff Multipliers</Checkbox>
            </Form.Item>
            }
            <Form.Item label="Limit Number of Players from a Single Team?">
              <Radio.Group onChange={numTeamPlayersOnChange}  value={teamPlayersRestricted}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            {teamPlayersRestricted &&
              <Form.Item label="Max Number of Players from Single Team" name="maxteamplayers" rules={[{ required: true, message: "Enter a max number of players from a single team." }]}>
                <InputNumber min={1} precision={0} defaultValue={1}/>
              </Form.Item>
            }
          </span >
          }
          <div>
          {leagueSport==="NFL" ?
            <Table
            dataSource={!teamPlayersRestricted ? PCrosterData: StackrosterData}
            columns={NFLrosterColumns}
            pagination={false}
            size= {"small"} />
          :
            <div>In this format, each roster selects one of each seed.<br></br><br></br></div>
          }
          </div>
          <Form.Item>
            <Checkbox disabled={!defaultEnabled} checked={allDefaultAgreement} onChange={(e) => setAllDefaultAgreement(e.target.checked)}>Lock in Defaults</Checkbox>
            {defaultEnabled && <div>Keeping this box checked means you will be unable to change the scoring or transaction settings of the league.
            The benefit of this is that leagues that are locked in with the default settings can have particpants share their entries/portfolios across leagues
            without needing to recreate them<br></br><br></br></div>}
          </Form.Item>
        </span>
      )}


      <Form.Item>
        <Button type="primary" htmlType="submit" >Create League</Button>
      </Form.Item>
    </Form>}
    {((isCompleted) && 
    <div><p>League Successfully Created. Invite others to join with this link: {LgJoinURL}</p>
      <Form>
        <Form.Item>
          <Button  onClick={(e) => {setIsCompleted(false)}} >Create Another League</Button>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Link to={"/league/" + createdLgInfo.league_id}>
            <Button>Go To My Created League</Button>
          </Link>
        </Form.Item>
      </Form>
      <Form>
        <Form.Item>
          <Link to="/portfolio/create">
            <Button>Create Portfolio for League</Button>
          </Link>
        </Form.Item>
      </Form>
      </div>
    )}
    {isErr && <div>{errMsg}
      <Form>
        <Form.Item>
          <Button  onClick={tryAgain} >Try Again?</Button>
        </Form.Item>
      </Form></div>}
    </div>
  )
}

export default CreateLeaguePage;