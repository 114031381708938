import React from "react";
import './LandingPage.css';
import { Link } from 'react-router-dom';

const MarchMadnessLandingPage = (props) => {



  return (
    <div>
      <section className="hero">
        <h1 className="hero-title">Welcome to Market Madness</h1>
        <p className="hero-description">
        Use your March Madness knowledge to invest in the Cinderellas of the tournament.
        </p>
        <Link to="/signup" className="cta-button">Get Started</Link>
        <section className="features">
        <div className="feature">
          <div className="feature-icon">📈</div>
          <h2>Player Trading</h2>
          <p>Maximize your portfolio by buying, selling, and trading teams based on their real-world performance.</p>
        </div>
        <div className="feature">
          <div className="feature-icon">🏆</div>
          <h2>Compete & Win</h2>
          <p>Challenge your friends and rise to the top of the leaderboard with your strategic investments.</p>
        </div>
      </section>
      </section>





      </div>

      
  );
}

export default MarchMadnessLandingPage;
