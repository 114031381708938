import React, {useState,useContext} from "react";
import axios from "./axios";

import {Button, Form, Input} from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./contexts/Authentication";
import { validateRedirectPath } from './utils/pathValidation';

const LogInPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {auth, setAuth} = useContext(AuthContext);
  const [errMsg, setErrMsg] = useState("");

  // Get and validate the redirect path from state
  const from = validateRedirectPath(location.state?.from);

  const submitLogIn = async (e) => {
    console.log("Handle log in Submit");
    console.log(e.username);
    var user = e.username;
    
    try {
      const response = await axios.post("/login",
        JSON.stringify(e),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const access = response?.data?.accessToken;
      const role = response?.data?.role;
      console.log("accessToken: " + access);
      console.log("role: " + role);
      console.log("auth is: " + auth);
      console.log("auth is: " + auth.values);

      setAuth({user, role, access});

      // Navigate to the validated protected page
      navigate(from, { replace: true });

    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      }
      else if (err.response?.status === 400){
        setErrMsg('Missing username or password');
      }
      else if (err.response?.status === 401){
        setErrMsg('Incorrect username and password combination.');
      }
      else {
        setErrMsg("Other Error");
      }
    }
  };

  return (
    <div>
      <Form onFinish={submitLogIn}>
        <Form.Item label="Username" name="username" rules={[{ required: true, message: "Username required"  }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Password" name="pwd" rules={[{ required: true, message: "Password required"  }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item>
          {/*<Checkbox>Remember Me</Checkbox>*/}
          {/*<div>
            <a href="">Forgot Password?</a>
        </div>*/}
        </Form.Item>
        <div color='red'>{errMsg}</div>
        <Form.Item>
          <Button type="primary" htmlType="submit">Log In</Button>
          <div>
            Don't Have an Account? Sign Up <Link to="/signup" state={{ from }}>here</Link>
          </div>
          <div>
            Forgot Your Password? Click <Link to="/forgotpassword" state={{ from }}>here</Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LogInPage;