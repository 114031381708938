/**
 * Validates if a path is safe for internal redirection
 * @param {string} path - The path to validate
 * @returns {string} - Returns the validated path or a safe default
 */
export const validateRedirectPath = (path) => {
  // If path is undefined or null, return default
  if (!path) return '/home';

  // Ensure the path starts with a slash
  const normalizedPath = path.startsWith('/') ? path : `/${path}`;

  // List of allowed path prefixes
  const allowedPaths = [
    '/portfolio',
    '/portfolios',
    '/league',
    '/leagues',
    '/admin',
    '/market'
  ];

  // Check if the path starts with any of the allowed prefixes
  const isAllowedPath = allowedPaths.some(allowed => 
    normalizedPath.startsWith(allowed)
  );

  // If path is allowed, return it; otherwise return default
  return isAllowedPath ? normalizedPath : '/home';
}; 